var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GoBack',{attrs:{"to":{ name: 'ProdukteShow', params: { produktId: _vm.prod } }}},[_vm._v(" Produkt ")]),_c('h1',[_vm._v("Variante → anlegen")]),_c('VForm',{on:{"submit":_vm.submit}},[_c('FormBoxFields',[_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"beschreibung"}},[_vm._v("Beschreibung")]),_c('FormTextarea',{attrs:{"name":"beschreibung","width":"32rem","af":true},model:{value:(_vm.formData.beschreibung),callback:function ($$v) {_vm.$set(_vm.formData, "beschreibung", $$v)},expression:"formData.beschreibung"}})],1),_vm._l((_vm.requiredEigenschaftsarten),function(ref){
var id = ref.id;
var titel = ref.titel;
return _c('FormRowFields',{key:id},[_c('FormLabelInline',{attrs:{"for":titel}},[_vm._v(_vm._s(titel)+"*")]),_c('select',{attrs:{"name":titel},on:{"change":_vm.selectRequiredEigenschaft}},_vm._l((_vm.eigenschaftenForArt(id)),function(eigenschaft){return _c('option',{key:eigenschaft.id,domProps:{"value":eigenschaft.id}},[_vm._v(" "+_vm._s(eigenschaft.titel)+" ")])}),0)],1)}),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"eigenschaften"}},[(_vm.requiredEigenschaftsarten.length)?[_vm._v("Weitere")]:_vm._e(),_vm._v(" Eigenschaften ")],2),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedEigenschaftsartId),expression:"selectedEigenschaftsartId"}],staticClass:"select-eigenschaftsarten",attrs:{"name":"eigenschaften"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedEigenschaftsartId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.remainingEigenschaftsarten),function(ref){
var id = ref.id;
var label = ref.label;
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(" "+_vm._s(label)+" ")])}),0),(_vm.selectedEigenschaftsartId)?_c('select',{staticClass:"select-eigenschaften",attrs:{"name":"eigenschaften"},on:{"change":_vm.selectOptionalEigenschaft}},[_c('option',{domProps:{"value":null}}),_vm._l((_vm.eigenschaftenForArt(_vm.selectedEigenschaftsartId)),function(ref){
var id = ref.id;
var titel = ref.titel;
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(" "+_vm._s(titel)+" ")])})],2):_vm._e()],1),(_vm.formData.eigenschaften.length > _vm.requiredEigenschaftsarten.length)?_c('FormRowFields',[_c('ul',{staticClass:"selected-list"},_vm._l((_vm.selectedOptionalEigenschaften),function(id){return _c('li',{key:id},[_c('span',{staticClass:"eigenschaftsart"},[_vm._v(" "+_vm._s(_vm.eigenschaftsarten.find( function (art) { return art.id == _vm.eigenschaften.find(function (e) { return e.id == id; }).eigenschaftsartId; } ).titel)+": ")]),_c('span',[_vm._v(_vm._s(_vm.eigenschaften.find(function (e) { return e.id == id; }).titel))]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeEigenschaft(id)}}},[_c('XCircleIcon',{staticClass:"remove-icon"})],1)])}),0)]):_vm._e(),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"preisId"}},[_vm._v("Preis (überschreibt Produktpreis)")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.preisId),expression:"formData.preisId"}],attrs:{"name":"preisId"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "preisId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"selected"},domProps:{"value":null}},[_vm._v("—")]),_vm._l((_vm.preise),function(ref){
var id = ref.id;
var label = ref.label;
var cent = ref.cent;
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(" "+_vm._s(label)+" ("+_vm._s(_vm.formatPrice(cent))+") ")])})],2)],1),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"bestand"}},[_vm._v("Bestand (tatsächlich)*")]),_c('FormInput',{attrs:{"name":"bestand","type":"number","steps":"1","rqd":true,"width":"3rem"},model:{value:(_vm.formData.bestand),callback:function ($$v) {_vm.$set(_vm.formData, "bestand", $$v)},expression:"formData.bestand"}})],1),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"bestandVersteckt"}},[_vm._v("...davon versteckt")]),_c('FormInput',{attrs:{"name":"bestandVersteckt","type":"number","steps":"1","width":"3rem"},model:{value:(_vm.formData.bestandVersteckt),callback:function ($$v) {_vm.$set(_vm.formData, "bestandVersteckt", $$v)},expression:"formData.bestandVersteckt"}})],1),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"bestandSchwelle"}},[_vm._v("Warnung für niedrigen Bestand")]),_c('FormInput',{attrs:{"name":"bestandSchwelle","type":"number","steps":"1","width":"3rem"},model:{value:(_vm.formData.bestandSchwelle),callback:function ($$v) {_vm.$set(_vm.formData, "bestandSchwelle", $$v)},expression:"formData.bestandSchwelle"}})],1)],2),_c('FormRowButtons',[_c('VButton',{attrs:{"type":"submit"}},[_vm._v("Speichern")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }