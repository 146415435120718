<template>
  <div>
    <GoBack :to="{ name: 'ProdukteIndex', query: { kat: kategorie.id } }">
      Produkte: {{ kategorie.titel.plural }}
    </GoBack>

    <GoBack :to="{ name: 'ProduktgruppenShow', params: { produktgruppeId: produktgruppe.id } }">
      Produktgruppe: {{ produktgruppe.titel }}
    </GoBack>

    <GoBack :to="{ name: 'ProdukteShow', params: { produktId: produkt.id } }">
      Produkt:
      <template v-if="produktgruppe.produktfilter.length">
        <span
          class="inline-list"
          v-for="{ id, titel } in produkt.eigenschaften.filter(e =>
            produktgruppe.produktfilter.map(f => f.id).includes(e.eigenschaftsartId)
          )"
          :key="id"
        >
          {{ titel }}
        </span>
      </template>
      <template v-else>&lt;Default&gt;</template>
    </GoBack>

    <GoBack :to="{ name: 'VariantenShow', params: { varianteId } }">
      Variante:
      <template v-if="produkt.variantenfilter.length">
        <span
          class="inline-list"
          v-for="{ id, titel } in variante.eigenschaften.filter(e =>
            produkt.variantenfilter.map(f => f.id).includes(e.eigenschaftsartId)
          )"
          :key="id"
        >
          {{ titel }}
        </span>
      </template>
      <template v-else>&lt;Default&gt;</template>
    </GoBack>

    <h1>
      Variante:
      <template v-if="produkt.variantenfilter.length">
        <span
          class="inline-list"
          v-for="{ id, titel } in variante.eigenschaften.filter(e =>
            produkt.variantenfilter.map(f => f.id).includes(e.eigenschaftsartId)
          )"
          :key="id"
        >
          {{ titel }}
        </span>
      </template>
      <template v-else>&lt;Default&gt;</template>
      &rarr; bearbeiten
    </h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormRowFields>
          <FormLabelInline for="beschreibung">Beschreibung</FormLabelInline>
          <FormTextarea
            v-model="formData.beschreibung"
            name="beschreibung"
            width="32rem"
            :af="true"
          />
        </FormRowFields>

        <!-- Optional Eigenschaften -->
        <FormRowFields>
          <FormLabelInline for="eigenschaften">
            Eigenschaften
          </FormLabelInline>
          <select
            class="select-eigenschaftsarten"
            name="eigenschaften"
            v-model="selectedEigenschaftsartId"
          >
            <option v-for="{ id, label } in remainingEigenschaftsarten" :key="id" :value="id">
              {{ label }}
            </option>
          </select>
          <select
            class="select-eigenschaften"
            v-if="selectedEigenschaftsartId"
            name="eigenschaften"
            @change="selectOptionalEigenschaft"
          >
            <option :value="null"></option>
            <option
              v-for="{ id, titel } in eigenschaftenForArt(selectedEigenschaftsartId)"
              :key="id"
              :value="id"
            >
              {{ titel }}
            </option>
          </select>
        </FormRowFields>

        <FormRowFields v-if="formData.eigenschaften.length > requiredEigenschaftsarten.length">
          <ul class="selected-list">
            <li v-for="id in selectedOptionalEigenschaften" :key="id">
              <span class="eigenschaftsart">
                {{
                  eigenschaftsarten.find(
                    art => art.id == eigenschaften.find(e => e.id == id).eigenschaftsartId
                  ).titel
                }}:
              </span>
              <span>{{ eigenschaften.find(e => e.id == id).titel }}</span>
              <span class="remove" @click="removeEigenschaft(id)">
                <XCircleIcon class="remove-icon" />
              </span>
            </li>
          </ul>
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="preisId">Preis (überschreibt Produktpreis)</FormLabelInline>
          <select name="preisId" v-model="formData.preisId">
            <option :value="null" selected="selected">&mdash;</option>
            <option v-for="{ id, label, cent } in preise" :key="id" :value="id">
              {{ label }} ({{ formatPrice(cent) }})
            </option>
          </select>
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="bestandVersteckt">...davon versteckt</FormLabelInline>
          <FormInput
            v-model="formData.bestandVersteckt"
            name="bestandVersteckt"
            type="number"
            steps="1"
            width="3rem"
          />
        </FormRowFields>

        <FormRowFields>
          <FormLabelInline for="bestandSchwelle">Warnung für niedrigen Bestand</FormLabelInline>
          <FormInput
            v-model="formData.bestandSchwelle"
            name="bestandSchwelle"
            type="number"
            steps="1"
            width="3rem"
          />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit">Speichern</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import formatPrice from '@/assets/js/formatPrice'

import FormComponents from '@/components/FormComponents'
import GoBack from '@/components/global/GoBack'
import VButton from '@/components/global/Button'

import XCircleIcon from '@/components/cmsIcons/XCircleIcon'

export default {
  name: 'VariantenEdit',
  components: {
    ...FormComponents,
    GoBack,
    VButton,
    XCircleIcon,
  },
  props: ['varianteId'],
  data() {
    return {
      formData: null,
      formErrors: null,
      selectedEigenschaftsartId: null,
    }
  },
  computed: {
    variante() {
      return store.getters['varianten/variante'](this.varianteId)
    },
    produkt() {
      return store.getters['produkte/produkt'](this.variante.produktId)
    },
    produktgruppe() {
      return store.getters['produktgruppen/produktgruppe'](this.produkt.produktgruppeId)
    },
    kategorie() {
      return store.getters['kategorien/kategorie'](this.produktgruppe.kategorieId)
    },
    eigenschaftsarten: () => store.getters['eigenschaftsarten/eigenschaftsarten'],
    eigenschaften: () => store.getters['eigenschaften/eigenschaften'],
    preise: () => store.getters['preise/preise'],
    requiredEigenschaftsarten() {
      return this.eigenschaftsarten.filter(art =>
        this.produkt.variantenfilter.map(f => f.id).includes(art.id)
      )
    },
    optionalEigenschaftsarten() {
      return this.eigenschaftsarten.filter(
        art =>
          !(
            this.produktgruppe.produktfilter.map(f => f.id).includes(art.id) ||
            this.produkt.eigenschaften.map(e => e.eigenschaftsartId).includes(art.id) ||
            this.produkt.variantenfilter.map(f => f.id).includes(art.id)
          )
      )
    },
    remainingEigenschaftsarten() {
      const selectedEigenschaftsartenIds = this.formData.eigenschaften.map(
        selId => this.eigenschaften.find(e => e.id == selId).eigenschaftsartId
      )
      return this.optionalEigenschaftsarten.filter(
        art => !selectedEigenschaftsartenIds.includes(art.id)
      )
    },
    selectedOptionalEigenschaften() {
      return this.formData.eigenschaften.filter(selId =>
        this.optionalEigenschaftsarten
          .map(art => art.id)
          .includes(this.eigenschaften.find(e => e.id == selId).eigenschaftsartId)
      )
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      store.dispatch('varianten/getAll'),
      store.dispatch('produkte/getAll'),
      store.dispatch('produktgruppen/getAll'),
      store.dispatch('kategorien/getAll'),
      store.dispatch('eigenschaftsarten/getAll'),
      store.dispatch('eigenschaften/getAll'),
      store.dispatch('preise/getAll'),
    ])
    next()
  },
  created() {
    this.formData = {
      beschreibung: this.variante.beschreibung,
      eigenschaften: this.variante.eigenschaften.map(eigenschaft => eigenschaft.id),
      preisId: this.variante.preis ? this.variante.preis.id : null,
      bestandVersteckt: this.variante.bestand.versteckt,
      bestandSchwelle: this.variante.bestand.schwelle,
    }
  },
  methods: {
    formatPrice(cents) {
      return formatPrice(cents)
    },
    eigenschaftenForArt(artId) {
      return store.getters['eigenschaften/eigenschaftenForEigenschaftsart'](artId)
    },
    selectRequiredEigenschaft(event) {
      const id = event.target.value
      const artId = this.eigenschaften.find(e => e.id == id).eigenschaftsartId
      const index = this.formData.eigenschaften.findIndex(
        selected => this.eigenschaften.find(e => e.id == selected).eigenschaftsartId == artId
      )
      this.formData.eigenschaften[index] = id
    },
    selectOptionalEigenschaft(event) {
      this.formData.eigenschaften.push(event.target.value)
      this.selectedEigenschaftsartId = null
      event.target.value = null
    },
    removeEigenschaft(id) {
      this.formData.eigenschaften.splice(
        this.formData.eigenschaften.findIndex(selId => selId == id),
        1
      )
    },
    async submit() {
      try {
        await this.$store.dispatch('varianten/update', {
          id: this.varianteId,
          formData: this.formData,
        })
        this.$router.push({
          name: 'VariantenShow',
          params: { varianteId: this.varianteId },
        })
      } catch (err) {
        console.log(err.errors)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.select-eigenschaftsarten,
.select-eigenschaften {
  width: 15rem;
}

.select-eigenschaften {
  margin-left: 2rem;
}

.selected-list {
  margin-left: 16rem;

  li:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.eigenschaftsart {
  margin-right: 0.25rem;
  font-weight: $weight-semibold;
}

.remove {
  display: inline-block;
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}

.remove-icon {
  display: inline-block;
  height: 1.25rem;
  transform: translateY(0.25rem);
  fill: $col-red-500;
}
</style>
