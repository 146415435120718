var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GoBack',{attrs:{"to":{ name: 'ProdukteIndex', query: { kat: _vm.kategorie.id } }}},[_vm._v(" Produkte: "+_vm._s(_vm.kategorie.titel.plural)+" ")]),_c('GoBack',{attrs:{"to":{ name: 'ProduktgruppenShow', params: { produktgruppeId: _vm.produktgruppe.id } }}},[_vm._v(" Produktgruppe: "+_vm._s(_vm.produktgruppe.titel)+" ")]),_c('GoBack',{attrs:{"to":{ name: 'ProdukteShow', params: { produktId: _vm.produktId } }}},[_vm._v(" Produkt: "),(_vm.produktgruppe.produktfilter.length)?_vm._l((_vm.produkt.eigenschaften.filter(function (e) { return _vm.produktgruppe.produktfilter.map(function (f) { return f.id; }).includes(e.eigenschaftsartId); }
        )),function(ref){
        var id = ref.id;
        var titel = ref.titel;
return _c('span',{key:id,staticClass:"inline-list"},[_vm._v(" "+_vm._s(titel)+" ")])}):[_vm._v("<Default>")]],2),_c('h1',[_vm._v(" Produkt: "),(_vm.produktgruppe.produktfilter.length)?_vm._l((_vm.produkt.eigenschaften.filter(function (e) { return _vm.produktgruppe.produktfilter.map(function (f) { return f.id; }).includes(e.eigenschaftsartId); }
        )),function(ref){
        var id = ref.id;
        var titel = ref.titel;
return _c('span',{key:id,staticClass:"inline-list"},[_vm._v(" "+_vm._s(titel)+" ")])}):[_vm._v("<Default>")],_vm._v(" → bearbeiten ")],2),_c('VForm',{on:{"submit":_vm.submit}},[_c('FormBoxFields',[_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"teaser"}},[_vm._v("Teaser")]),_c('FormTextarea',{attrs:{"name":"teaser","width":"32rem","rows":2},model:{value:(_vm.formData.teaser),callback:function ($$v) {_vm.$set(_vm.formData, "teaser", $$v)},expression:"formData.teaser"}})],1),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"beschreibung"}},[_vm._v("Beschreibung")]),_c('FormTextarea',{attrs:{"name":"beschreibung","width":"32rem"},model:{value:(_vm.formData.beschreibung),callback:function ($$v) {_vm.$set(_vm.formData, "beschreibung", $$v)},expression:"formData.beschreibung"}})],1),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"hinweis"}},[_vm._v("Hinweis")]),_c('FormTextarea',{attrs:{"name":"hinweis","width":"32rem"},model:{value:(_vm.formData.hinweis),callback:function ($$v) {_vm.$set(_vm.formData, "hinweis", $$v)},expression:"formData.hinweis"}})],1),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"eigenschaften"}},[_vm._v(" Eigenschaften ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedEigenschaftsartId),expression:"selectedEigenschaftsartId"}],staticClass:"select-eigenschaftsarten",attrs:{"name":"eigenschaften"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedEigenschaftsartId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.remainingEigenschaftsarten),function(ref){
        var id = ref.id;
        var label = ref.label;
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(" "+_vm._s(label)+" ")])}),0),(_vm.selectedEigenschaftsartId)?_c('select',{staticClass:"select-eigenschaften",attrs:{"name":"eigenschaften"},on:{"change":_vm.selectOptionalEigenschaft}},[_c('option',{domProps:{"value":null}}),_vm._l((_vm.eigenschaftenForArt(_vm.selectedEigenschaftsartId)),function(ref){
        var id = ref.id;
        var titel = ref.titel;
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(" "+_vm._s(titel)+" ")])})],2):_vm._e()],1),(_vm.formData.eigenschaften.length > _vm.requiredEigenschaftsarten.length)?_c('FormRowFields',[_c('ul',{staticClass:"selected-list"},_vm._l((_vm.selectedOptionalEigenschaften),function(id){return _c('li',{key:id},[_c('span',{staticClass:"eigenschaftsart"},[_vm._v(" "+_vm._s(_vm.eigenschaftsarten.find( function (art) { return art.id == _vm.eigenschaften.find(function (e) { return e.id == id; }).eigenschaftsartId; } ).titel)+": ")]),_c('span',[_vm._v(_vm._s(_vm.eigenschaften.find(function (e) { return e.id == id; }).titel))]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeEigenschaft(id)}}},[_c('XCircleIcon',{staticClass:"remove-icon"})],1)])}),0)]):_vm._e(),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"preisId"}},[_vm._v("Preis*")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.preisId),expression:"formData.preisId"}],attrs:{"name":"preisId"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "preisId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.preise),function(ref){
        var id = ref.id;
        var label = ref.label;
        var cent = ref.cent;
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(" "+_vm._s(label)+" ("+_vm._s(_vm.formatPrice(cent))+") ")])}),0)],1),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"quickbuy"}},[_vm._v("Quickbuy")]),_c('VToggle',{attrs:{"name":"quickbuy","value":_vm.formData.quickbuy},on:{"click":_vm.toggleQuickbuy}})],1),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"neuFuer"}},[_vm._v("Neu für (in Tagen)")]),_c('FormInput',{attrs:{"name":"neuFuer","type":"number","steps":"1","width":"3rem"},model:{value:(_vm.formData.neuFuer),callback:function ($$v) {_vm.$set(_vm.formData, "neuFuer", $$v)},expression:"formData.neuFuer"}})],1),_c('FormRowFields',[_c('FormLabelInline',{attrs:{"for":"variantenfilter"}},[_vm._v(" Varianten-Unterscheidungsmerkmale ")]),_c('select',{staticClass:"select-eigenschaftsarten",attrs:{"name":"variantenfilter"},on:{"change":_vm.selectVariantenfilter}},[_c('option',{domProps:{"value":null}}),_vm._l((_vm.remainingEigenschaftsarten),function(ref){
        var id = ref.id;
        var label = ref.label;
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(" "+_vm._s(label)+" ")])})],2)],1),(_vm.formData.variantenfilter.length)?_c('FormRowFields',[_c('ul',{staticClass:"selected-list"},_vm._l((_vm.formData.variantenfilter),function(id){return _c('li',{key:id},[_c('span',{staticClass:"eigenschaftsart"},[_vm._v(" "+_vm._s(_vm.eigenschaftsarten.find(function (art) { return art.id == id; }).label)+" ")]),_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeVariantenfilter(id)}}},[_c('XCircleIcon',{staticClass:"remove-icon"})],1)])}),0)]):_vm._e()],1),_c('FormRowButtons',[_c('VButton',{attrs:{"type":"submit"}},[_vm._v("Speichern")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }